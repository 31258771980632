import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Main from './pages/Main';
import { getLastUserLogin } from './apiservices/ApiServices';
import { format } from 'date-fns';

function App() {
  //Disable right-click
  // document.addEventListener('contextmenu', (e) => e.preventDefault());

  // function ctrlShiftKey(e, keyCode) {
  //   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  // }

  // document.onkeydown = (e) => {
  //   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  //   if (
  //     e.keyCode === 123 ||
  //     ctrlShiftKey(e, 'I') ||
  //     ctrlShiftKey(e, 'J') ||
  //     ctrlShiftKey(e, 'C') ||
  //     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  //   )
  //     return false;
  // };

  useEffect(() => {
    setInterval(() => {
      if (!localStorage.getItem('userToken')) {

      }
      else {
          getLastUserLogin().then((res) => 
          {
              try {
                  let utcDate = res.data.logDate;
                  logindate(utcDate)                     
              } catch (error) {
                  console.error(error);
              } finally {
              }
          });
      }
    }, 10000);
  },[]);

  const logindate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    localStorage.setItem('lastlogin', format(date, 'd MMMM yyyy hh:mma'));
  };
  return (
    <>
      <BrowserRouter>  
          <Main />     
      </BrowserRouter>
    </>
  );
}

export default App;