import React from 'react';
import { Container } from 'react-bootstrap';
import Logo from "../../assets/images/orzilogo.svg";
import {Link } from "react-router-dom";

const TermsConditions = () => {
  return (
    <>
      <header id='header'>
          <div className='header-block'>
              <div className='logo-box' id='heds'>
                  <Link to={'/'}>
                      <img src={Logo} alt='' title='' />
                  </Link>
              </div>
              <div className='rightnav'>
                  <ul>
                      <li><h6>ORZI.App</h6></li>                        
                  </ul>
              </div>
          </div>
      </header>
      <section id="contents">
        <div class="contents-block">
            <Container>
                <div class="contents-box">
                    <h1>Terms and Condition</h1>
                    <p>This document is ORZI’s terms and conditions (“the Terms and Conditions”) for the use of our platform for competition purposes.</p>
                    <h4>Definitions:</h4>
                    <p>In these Terms and Conditions:</p>
                    <p><b>“we”</b> , <b>“ORZI”</b> or <b>“the Company”</b> means Ezo Connect Limited (trading as ORZI) as referred to in paragraph 1.1;</p>
                    <p><b>“Advertiser”</b> means a business customer of Orzi who wants to use the ORZI platform or SMS shortcodes for a Competition;</p>
                    <p><b>“Competition”</b> means a voting event or a competition operated by Orzi;</p>
                    <p><b>“Entrant”</b> means an entrant to a Competition, as defined in paragraph 3.2, and any reference to <b>“you”</b> is to an Entrant;</p>
                    <p><b>“Prize”</b> means the prize for a Competition, as defined in paragraph 6.1;</p>
                    <p><b>“Website”</b> or <b>“Site”</b> means the ORZI website, https://orzi.app</p>
                    <p><b>“Winner”</b> means a winner of a Competition, as announced by the Advertiser in relation that Competition in accordance with paragraph 7.1 or 7.2.</p>

                    <h4>Competition Terms & Conditions</h4>
                    <ol>
                      <li class="main"><span>The Company</span>
                        <ol>
                          <li>Ezo Connect Ltd (trading as ORZI) Company Number 122355 registered in Jersey and whose registered office is at ORZI, Regus, Floor 1 Liberation Station Esplanade, St Helier, JE2 3AS</li>
                          <li>The Company will be referred to in these Terms and Conditions as “ORZI”</li>
                          <li>Our correspondence address is ORZI, Regus, Floor 1 Liberation Station, Esplanade, St Helier, JE2 3AS</li>
                          <li>These Terms and Conditions apply to the use of ORZI’s website https://orzi.app, <b>which is referred to as the “WebSite” or the “Site”) in these Terms and Conditions.</b></li>
                          <li>If you wish to contact ORZI for any reason, please email support@orzi.app</li>
                        </ol>
                      </li>
                      <li class="main"><span>The Advertiser</span>
                        <ol>
                          <li>ORZI’s Website provides third party businesses and organisations who are customers of ORZI, with a publicly accessible platform to facilitate voting events and competitions. These third party businesses and organisations will be referred to in these Terms and Conditions as the <b>“Advertiser”</b>.</li>
                          <li>All voting events and competitions run by Orzi on behalf of an Advertiser (each being referred to in these Terms and Conditions as a “Competition”) will adhere to these Terms and Conditions.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Competitions</span>
                        <ol>
                          <li>It is important to read the terms and conditions for each individual Competition. Links to the individual Advertiser’s Terms and Conditions for each Competition will be available within the voting event/competition section on ORZI’s Website </li>
                          <li>An entry fee is payable each time you enter a Competition via ORZIs online entry method <b>(“Online Entry Route”)</b>. Each person who enters a Competition via the Online Entry Route is an <b>“Entrant”</b> for the purpose of these Terms and Conditions.</li>
                          <li>These Terms and Conditions apply to all Entrants, alongside the Advertiser’s Terms and Conditions.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Eligibility</span>
                        <ol>
                          <li>Each Competition is open to anyone (each, an Entrant for the purposes of these Terms and Conditions) who is aged 18 years or over, and who is resident in a country where it is lawful for the Entrant to enter the Competition and for Orzi to promote the Competition and award the prize for that Competition to the Entrant, except:
                            <ol>
                              <li>employees of Orzi;</li>
                              <li>employees of agents or suppliers of the Advertiser, who are professionally connected with the relevant Competition or its administration; or</li>
                              <li>members of the households of (4.1.1) and (4.1.2) above.</li>
                            </ol>
                          </li>
                          <li>By entering a Competition, the Entrant confirms that you are eligible to do so and eligible to claim any Prize you may win. Either ORZI or the Advertiser may require you to provide proof that you are eligible to enter the Competition (see paragraph 7 below). If you fail to provide ORZI or the Advertiser with any such proof or other information that they may require within 10 days of a request by ORZI or the Advertiser, you may be disqualified from the Competition and an alternative winner may be selected.</li>
                          <li>ORZI will not accept Competition entries that are:
                            <ol>
                              <li>automatically generated by computer;</li>
                              <li>submitted by agents, third parties or syndicated entries; or</li>
                              <li>Incomplete.</li>
                            </ol>
                          </li>
                          <li>ORZI and the Advertiser reserve all rights to disqualify the Entrant from any individual Competition if:
                            <ol>
                              <li>ORZI or the Advertiser reasonably believes that you have acted in breach of these Terms and Conditions or the Advertiser’s Terms and Conditions;</li>
                              <li>ORZI or the Advertiser reasonably believes that you have acted in concert with other persons (including members of your household), or have submitted entries purporting to be in the name of another person, with the intention of circumventing these Terms and Conditions, or the Advertiser’s Terms and Conditions, or breaching any applicable laws or regulations;</li>
                              <li>it is, or has become, illegal for ORZI or the Advertiser to promote the Competition to you and/or award a prize to you;</li>
                              <li>you are found to be abusing or tampering with the operation of the Competition, the Website, or entering using fraudulent means; or</li>
                              <li>you are found to have posted an entry or comment to ORZI’s or the Advertiser’s social media that is, in ORZIs opinion, inappropriate, offensive or upsetting to other Entrants or the Advertiser or ORZI or is contrary to any applicable law.</li>
                            </ol>
                          </li>
                          <li>Refunds will only be provided in exceptional circumstances and at the sole discretion of ORZI or the Advertiser. No refunds of the entry fee will be given:
                            <ol>
                              <li>if, following your entry into a Competition, you subsequently find out that you are not eligible to enter the Competition or claim the Prize; or</li>
                              <li> if you are disqualified from the Competition by the Advertiser or ORZI in accordance with the Advertiser’s Terms and Conditions or these Terms and Conditions.</li>
                            </ol>
                          </li>
                          <li>Entrants can enter each Competition as many times as they wish until the maximum number of entries per user for that Competition have been submitted and until the maximum number of entries for the individual Competition have been received.</li>
                          <li>Only one account per Entrant is allowed.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Entries</span>
                        <ol>
                          <li>Each individual Competition will run from and to the opening and closing dates specified on ORZI’s Website and in the Advertiser’s Terms and Conditions for that Competition. These dates for each Competition shall be referred to as the “Opening Date” and “Closing Date” respectively. Please note that the Closing Date of each Competition available via the Online Entry Route may be the earlier of:
                            <ol>
                              <li>a set time and date, </li>
                              <li>the time and date on which the maximum number of entries have been received, or </li>
                              <li>the time and date on which a correct answer has been received for a skill-based question therefore ending the Competition.
                                <ol>
                                  <li>All times and dates referred to are the times and dates in Jersey.</li>
                                </ol>
                              </li>
                            </ol>
                          </li>
                          <li>If it is absolutely necessary to do so due to circumstances beyond the control of ORZI or the Advertiser, and where not to do so would be unfair to those who have already entered a Competition, Orzi or the Advertiser may change the Closing Date or correct an incorrectly displayed Opening Date as reflected on ORZI’s Website or the Advertiser’s website.
                            <ol>
                              <li>If Orzi or the Advertiser does change the Closing Date of a Competition or corrects an incorrectly displayed Opening Date, the new details will be displayed on ORZI’s Website and the Advertiser’s website and all Entrants will be notified.</li>
                            </ol>
                          </li>
                          <li>For each individual Competition, all entries must be received by Orzi and/or the Advertiser no earlier than the specified time on the Opening Date and no later than the specified time on the Closing Date for that Competition. All Competition entries received outside these times may be disqualified without a refund.</li>
                          <li>To enter an individual Competition using the Online Entry Route:
                            <ol>
                              <li>go to ORZI’s Website and view the Competition question;</li>
                              <li>provide your answer to the Competition question; then</li>
                              <li>complete the checkout process and submit the online entry form.</li>
                            </ol>
                          </li>
                          <li>All entries must be submitted in the English language. Entries in languages other than English will automatically be disqualified and no refund will be given.</li>
                          <li>Either ORZI or the Advertiser will send confirmation that your entry has been received by SMS to the mobile number connected to your account.</li>
                          <li>Neither ORZI nor the Advertiser will accept responsibility for Competition entries that are not successfully completed, are lost or are delayed regardless of cause, including, for example, as a result of any equipment failure, technical malfunction, systems, satellite, network, server, computer hardware or software failure of any kind.</li>
                          <li>    By purchasing entries and submitting a Competition entry, the Entrant is entering into a contract with ORZI and is agreeing to be bound by these Terms and Conditions.
                            <ol>
                              <li>For help with entries, please email us at support@orzi.app</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li class="main"><span>Prizes</span>
                        <ol>
                          <li>There may be one or more prizes available for each Competition, which are reflected on the ORZI Website for that Competition and/or described by the Advertiser on its website <b>(the “Prize”)</b>. Details of the Prizes for each Competition will be, to the best of ORZI’s knowledge, information and belief, correct as at the Opening Date for that Competition.</li>
                          <li>Non-cash Prizes are subject to availability.</li>
                          <li>ORZI makes no representations and gives no warranties about the Prize, its value, its condition or any other information provided by the Advertiser and reflected on the ORZI Website for that Competition. ORZI makes no representations and does not give any warranties that the information provided by the Advertiser is accurate, complete or up to date.</li>
                          <li>Any non-cash Prize may be subject to additional conditions, which will be set out on the ORZI Website for that Competition and set out by the Advertiser on its website.</li>
                          <li>Any non-cash Prize may be supplied for any Competition by a third-party supplier <b>(the “Supplier”)</b>. Details of the Supplier (if any) will be set out on the Orzi Website and by the Advertiser on its website for that Competition.</li>
                          <li>The Prize is not negotiable or transferable.</li>
                          <li>All costs and expenses not included within the Prize are the responsibility of the winner.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Winners</span>
                        <ol>
                            <li class="main"><span>Skill based Competitions</span>
                              <ol>
                                <li>Where Entrants have entered a Competition involving a skill based question in accordance with these Terms and Conditions, the identity of the winner or winners (each a <b>“Winner”</b>) will be the Entrant or Entrants (in a multiple winner Competition) who has submitted the correct answer first.</li>
                              </ol>
                            </li>
                            <li class="main"><span>Chance based Competitions</span><i>(NOTE: only available in jurisdictions where it is lawful to operate a chance based competition without that being regarded as gambling)</i>
                              <ol>
                                <li>Where Entrants have entered a Competition involving a chance based question in accordance with these Terms and Conditions, the Entrants will be placed into a draw and the identity of the winner or winners (in a multiple winner Competition) (each a <b>“Winner”</b>) will be chosen using a verifiably random draw process. The random draw for the Competition will take place on the Closing Date for that Competition (<b>“Draw Date”</b>).</li>
                                <li>In the event of the draw being disrupted for any reason or having an inconclusive outcome, ORZI can at their sole and unfettered discretion run the draw again, either through the same random draw process or an alternative method. </li>
                              </ol>
                            </li>
                            <li>In relation to each Competition, the decision of Orzi as to identity of the Winner(s) is final and no correspondence or discussion will be entered into.</li>
                            <li>Orzi will contact each Winner personally as soon as practicable after the Competition has ended, using the mobile number or email address provided on their ORZI website account.</li>
                            <li> If a selected Winner has won a prize with a value of £2,000 or above, the Winner may be asked to provide valid proof of identity.</li>
                            <li>The Advertiser must either publish or make available information that indicates that a valid award took place in relation to each Competition if so required by local regulations. To comply with this obligation the Advertiser will publish the details of major prize winners on their website Website/App/social media accounts unless the Entrant objects in accordance with paragraph below.</li>
                            <li>If the Entrant objects to any or all of his details and winning entry being published or made available, please contact the Advertiser prior to the Closing Date for the relevant Competition. In such circumstances, the Advertiser must still provide information to the relevant authorities on request if local regulations so require. </li>
                            <li>For each Competition, a link will be provided on the ORZI Website to the Advertiser’s website where details will be available (provided by the Advertiser) of the status of all Winner(s) following the conclusion of that Competition.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Claiming Prizes</span>
                        <ol>
                          <li>The Winner of each Competition must claim the Prize personally. The Prize may not be claimed by a third party on your behalf. Details of how the Prize will be delivered to you (or made available for collection) will be provided by the Advertiser. A link will be provided on the ORZI Website to the Advertiser’s website detailing this information.</li>
                          <li>If your personal details, including contact information, change at any time you should update your account on the ORZI Website as soon as reasonably possible. If your details change within 10 days of the Closing Date of a Competition, Orzi or the Advertiser may use your old details if it needs to try to contact you.</li>
                          <li>The Advertiser will provide Instructions for how to claim the Prize for each Competition. A link will be provided on the ORZI Website to the Advertiser’s website detailing this information.</li>
                          <li>Neither ORZI nor the Advertiser accepts any responsibility and each of them is not liable to pay any compensation if any Winner is unable to or does not take up the Prize.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Limitation of liability</span>
                        <ol>
                          <li>ORZI shall not be liable to anyone for any loss or damage howsoever arising from or in any way related to the Entrant, or any third party's, use or access of the Website or any Competition, be that direct or indirect, for any reason.</li>
                          <li>ORZI shall not be liable to anyone for any reason for any modification, suspension or termination of the Website or any Competition.</li>
                          <li>ORZI shall not be liable in any way for loss or damage howsoever arising from or in any way related to any party’s use of any link contained on the Website.</li>
                          <li>ORZI does not bear any responsibility for the content of any Advertiser’s or other site linked from the Website.</li>
                          <li>Each Entrant should read the details of any limitation of liability set out by the Advertiser under the Advertiser’s Terms and Conditions for any Competition.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Data protection and publicity</span>
                        <ol>
                          <li>By entering any Competition, you agree that any personal information provided by you in connection with the Competition entry may be held and used by ORZI or its agents and suppliers for the purposes of administering and managing the Competition and prizes or as otherwise set out in ORZI’s Privacy Policy, a copy of which is available by link on the Website. The Website also includes a link to the Advertiser’s website, which details the Advertiser’s privacy Policy.</li>
                          <li>If you are the Winner you may be asked to participate in reasonable publicity by the Advertiser. If you do not wish to participate in any publicity, you must notify Orzi and the Advertiser. This will not affect your chances of winning the Prize. If you do not agree to participate in any publicity about the Competition we may still provide your details to the relevant authorities. This is a legal requirement that we must comply with to prove that each Competition has been properly administered and the Prize awarded.</li>
                          <li>Please note that under data protection laws you are entitled to request that ORZI does not contact you and removes your details from its database. If you make such a request you will be withdrawing from the Competition as it will not be possible to contact you in the event that you are the winner. You will not be entitled to any refund of any entry fee if you withdraw from the Competition. If you do not wish any of your personal details to be used by Orzi for promotional purposes, please email Orzi at support@orzi.app</li>
                        </ol>
                      </li>
                      <li class="main"><span>General</span>
                        <ol>
                          <li>ORZI reserves the right to amend these Terms and Conditions from time to time. The latest version of these terms and conditions will be available on ORZI’s Website</li>
                          <li>If there is any reason to believe that there has been a breach of these Terms and Conditions (or of the Advertiser’s terms and conditions), ORZI may, at its sole discretion, exclude you from participating in the Competition and any future Competitions.</li>
                          <li>ORZI reserves the right to hold void, suspend, cancel, or amend any Competition at any time if circumstances beyond its control make this unavoidable. If a Competition is cancelled, ORZI will reapply any deducted credits used for entry into that Competition.</li>
                          <li>Where the Entry Fee is returned, ORZI shall have no further liability to the Entrant or to any other person.</li>
                          <li>None of the Competitions on ORZI’s Website are in any way sponsored, endorsed, administered by or associated with Facebook, Apple or Google. By entering a Competition, Entrants agree that neither Facebook, Apple nor Google have any liability and are not responsible for the administration or promotion of the Competition concerned.</li>
                          <li>The Entrant should print a copy of these Terms and Conditions and keep them for your records.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Governing Law</span>
                        <ol>
                          <li>These Terms and Conditions, the rights and remedies provided hereunder, and any and all claims and disputes related hereto, shall be governed by, construed under and enforced in all respects solely and exclusively in accordance with the laws of Jersey. Any and all such claims and disputes shall be brought in, and you hereby consent to them being decided exclusively by a court of competent jurisdiction located in Jersey.</li>
                        </ol>
                      </li>
                    </ol>

                    <h4>Website Terms of Service</h4>
                    <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</p>
                    <p>These terms tell you the rules for using our website https://www.orzi.app/ (our site).</p>
                    <p><b>The Site is operated by Ezo Connect Limited (the “Company”) trading as ORZI</b></p>
                    <p><b>Throughout these terms of service, “the User” means any individual, or entity that uses the Site or the Services (each as defined in paragraph 1.1)</b></p>
                    <ol>
                      <li class="main"><span>The Service</span>
                        <ol>
                          <li>These are the terms between the Company and the User for the usage of https://orzi.app (the “Site”) and SMS Short Code Entries (the “Services”) which is operated by the Company. The User, when agreeing these terms agrees to the Terms of Service as well as the Privacy Policy, these terms shall together be referred to as the “Agreement” which constitutes both a binding legal agreement between the User and the Company and governs the relationship between the User and the Company</li>
                        </ol>
                      </li>
                      <li class="main"><span>Acceptance and Amendment of the Agreement</span>
                        <ol>
                          <li>If the User fails to agree to the terms or conditions of the Agreement, the User must then immediately stop using the Site and the Services.</li>
                          <li>The Company may at any time amend the Agreement and any changes made shall come into effect on the 14th day after being published on the Site or, if required by any applicable law, regulation or directive, on such date as thereby specified. The User agrees that by continued use and access to the Site and Services following such a change they are deemed to accept any amendments made to the Agreement.</li>
                          <li>At all times it remains the User’s responsibility to ensure that they are aware of the up-to-date terms and conditions of the Agreement and it remains the User’s responsibility to stay abreast of any changes to the Terms of Service as well as the Privacy Policy.</li>
                          <li>The Company reserves the right to terminate or suspend User’s to the Site and any included Services at any time. This may be for any reason and at the Company’s sole discretion, with no financial compensation for the User.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Usage of the Site and Services</span>
                        <ol>
                          <li>The Site and the Services may not be used by the User unless they are over the age of 18 (the <b>"Legal Age"</b>).</li>
                        </ol>
                      </li>
                      <li class="main"><span>Site Content and Intellectual Property</span>
                        <ol>
                          <li>The Company, and wherever applicable its affiliates and licensors, have exclusive ownership of all software, data, written materials, graphics, forms, artwork, images, pictures, photographs, functional components, animations, videos, music, audio, text and any software concepts and documentation and other material on, in or made available through the Site (collectively the <b>“Site Content”</b>).</li>
                          <li>The User agrees to never remove or amend any copyright notice or other such proprietary notice on the Site or the Site Content.</li>
                          <li>Additionally, the brand names and any other trademarks, service marks and/or trade names used on the Site (the <b>“Trademarks”</b>) are property of the Company, its affiliates or licensors (as applicable) and these entities reserve all rights to such Trademarks.</li>
                          <li>The Site Content and Trademarks are protected by copyright and/or other intellectual property rights.</li>
                          <li>The User hereby acknowledges that by using the Site or the Services the User has obtained no rights in the Trademarks and the Site Content and the User may only use the same in accordance with the Agreement.</li>
                        </ol>
                      </li>
                      <li class="main"><span>User Content</span>
                        <ol>
                          <li>The Company may at its discretion permit the User to transmit, upload, post, e-mail or otherwise make available data, text, software, music, sound, photographs, graphics, images, videos, messages or other materials (<b>"User Content"</b>) on the Site. This may include but is not limited to content uploaded via online discussion forums and chat facilities.</li>
                          <li>The responsibility for such User Content is entirely held by the User and therefore the Company and its affiliates shall have no liability to the User relating to the User Content and the User waives all claims against the Company and its affiliates in this regard.</li>
                          <li>At no point are the Company or its affiliates obligated to monitor, moderate or review User Content.</li>
                          <li>By virtue of the provision of the User Content, the User grants the Company and its affiliates a perpetual, irrevocable, transferable, worldwide license to use, copy, perform exploit, distribute, reproduce, display, modify, add to, subtract from, translate, edit and create derivative works based upon the User Content or any portion thereof inclusive of and without limitation to promotional and advertising purposes in any manner and in any and all media now known or hereafter devised all without any compensation to the Customer whatsoever. The User also agrees to waive all moral rights to the User Content.</li>
                          <li>Any and all User Content may be at any time edited or removed by the Company and its affiliates. The User hereby waives any rights that it may have relating to the amending of the User Content.</li>
                          <li>The Company and its affiliates are not and shall not be responsible for any and all personally identifiable information uploaded by the User in any way as User Content. This includes any information that can be read, collected or used by third party visitors or users of the Site and/or the Services.</li>
                          <li>The User shall at all times while using the Service be polite when interacting with other users of the Site. The User shall not at any time engage in any behaviour that may be viewed as aggressive, harassing, tortious, defamatory, libellous, vulgar, hateful, obscene, offensive, racist, sexist, insulting or otherwise inappropriate towards other users.</li>
                          <li>The User agrees not to engage in or assist or encourage others to engage in the transmission, uploading, posting, or otherwise making available on the Site, User Content that:
                            <ol>
                                <li>is, or otherwise encourages activity or conduct that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, insulting, vulgar, obscene, pornographic, libellous, invasive of another's privacy, inappropriate, hateful, or racially, ethnically or otherwise objectionable;</li>
                                <li>violates any proprietary right, including intellectual property rights, of any third party or any rights of publicity, personality, or privacy of any person or entity. This is inclusive of violation by omission or failure to obtain required consents;</li>
                                <li>is untrue, malicious or damaging to the Company, its affiliates or the Site, whether intentional or unintentional;</li>
                                <li>is designed to interfere with or interrupt the Site from normal operation;</li>
                                <li>is intended to or infects the Site with a virus or any other method to interrupt, destroy or limit the functionality of the Site in any way; and</li>
                                <li>contains hidden or restricted content;</li>
                                <li>infringes any relevant law, statute, ordinance, regulation, or agreement;</li>
                                <li>promotes, advertises or otherwise supports any direct or indirect competitors of the Company, its affiliates or the Site.</li>
                            </ol>
                          </li>
                          <li>The User shall not intentionally misrepresent or make false statements regarding the origin or source of any User Content.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Prohibited Activities</span>
                        <ol>
                          <li>The User agrees not to and not to allow others to:
                            <ol>
                                <li>for any reason whatsoever access or collect any personally identifiable information of other users or visitors of the Site;</li>
                                <li>use the Site, the Services or the Site Content in relation to any unlawful activity;</li>
                                <li>copy, redistribute, publish, reverse engineer, decompile, disassemble, modify, translate or make any attempt to access the source code of the Site for any reason, inclusive of with intention to create a derivative version of the source code;</li>
                                <li>disclose to any third parties any data about the Site or the Services;</li>
                                <li>externally distribute the software to any third party;</li>
                                <li>distribute to the Site, other users or visitors of the Site or third parties any malicious code viruses or other such forms of malware or any device of similar intent;</li>
                                <li>modify, lease, copy, distribute, display, perform, reproduce, publish, licence, create derivative works from, transfer, or sell any of the Site Content, Trademarks or User Content;</li>
                                <li>to harvest or collect any data or information through the Site whatsoever, or enable any external tool or process to harvest or collect any data or information;</li>
                                <li>undertake any action that would negatively impact or harm the Company, its affiliates or the Sites’ reputation.</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li class="main"><span>User Undertakings, Representations and Affirmations</span>
                        <ol>
                          <li>By visiting or using the Site the User undertakes, represents and affirms that the User:
                            <ol>
                                <li>is of Legal Age;</li>
                                <li>waives any right to a participate in a class action or trial by jury against the Company or its affiliates in any jurisdiction where such waiver is possible and agree to instead consent to arbitral proceedings at the request of the Company in the event of any dispute.</li>
                                <li>will exclusively use the Site and Services for personal, non-commercial purposes;</li>
                                <li>shall at all times use the Site and the Services in total accordance with the terms and conditions of the Agreement, as amended;</li>
                                <li>has personally considered and determined that they will not violate any laws or regulations of any jurisdiction that applies to the User in their usage of the Site and Service, including but not limited to import, export, copyright, and trademark laws;</li>
                                <li>will not, in their usage of any facet of the Site or Services, deceitfully impersonate any individual, person, or entity;</li>
                            </ol>
                          </li>
                          <li>If the User is using the Site on behalf of an organization, company, or entity then the User must represent and affirm that it:
                            <ol>
                                <li>is authorized as a representative or agent of that organization, company or entity with sufficient authority enter into a binding Agreement;</li>
                                <li>has read the Agreement;</li>
                                <li>understands the Agreement, and </li>
                                <li>agrees to these Agreement on behalf of the organization, company or entity.</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li class="main"><span>Third-Party Content</span>
                        <ol>
                          <li>The User acknowledges that the Site may contain links to other locations or content operated by bodies other than the Company (collectively <b>“Third-Party Content”</b>). </li>
                          <li>The User agrees not to hold the Company in any way responsible for the Third-Party Content. The User bares all responsibility and risk for any and all interactions with Third-Party Content.</li>
                          <li>The Company in no way endorses nor makes any warranties or affirmations with respect to any such Third-Party Content be that relating to the accuracy or security of the content.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Disclaimer</span>
                        <ol>
                          <li>The User affirms that, in usage of the Site, the Services and all content on the Site, they are doing so at their sole discretion, option and risk.The Site, the Services and all materials on the Site or made available via the Services are made available on an "as is" basis. The Company, all affiliates and licensors disclaim absolutely all express or implied conditions, representations or warranties in relation to the Site, Services or all materials on the Site. This is inclusive of, but not limited to, merchantability, fitness for purpose, quality, accuracy, interference or infringement of any relevant laws.</li>
                          <li>The Company in no way guarantees that the Site, the Services or any materials on the Site will meet the User’s requirements or desires, or that the Site and Services are free of spyware, malware, or viruses.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Limitation of Liability</span>
                        <ol>
                          <li>The Company shall not be liable to anyone for any loss or damage howsoever arising from or in any way related to the User, or any third party's, use or access of the Site or the Services, be that direct or indirect, for any reason.</li>
                          <li>The User hereby affirms that the Company shall not be liable to anyone for any reason for any modification, suspension or termination of the Site or the Services.</li>
                          <li>The Company shall not be liable in any way for loss or damage howsoever arising from or in any way realtered to the User’s use of any link contained on the Site nor do they bear any responsibility for the content of Internet site linked from the Site.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Indemnity</span>
                        <ol>
                          <li>The User hereby agrees to fully indemnify, defend and hold the Company and all related bodies harmless immediately on demand from and against and all potential claims proceedings, liabilities or expenses of any kind, inclusive of legal fees, arising as a result of:
                            <ol>
                                <li>any breach of the Agreement by the User;</li>
                                <li>the access or use of the Site or the Services by the User or anyone using the User’s account details;</li>
                                <li>the User’s negligence;</li>
                                <li>the User’s violation of any law;</li>
                                <li>the User’s misconduct (collectively the <b>“Claims”</b>)</li>
                            </ol>
                          </li>
                          <li>The User hereby confirms that it will:
                            <ol>
                                <li>notify the Company of any Claim immediately;</li>
                                <li>not settle any Claim without the Company’s explicit prior written consent; and agrees that the Company or any relevant related body may assume the defence of any Claim, and that the User shall fully cooperate to any and all reasonable requests in the course of defence to the Claims.</li>
                            </ol>
                          </li>
                          <li>The User shall maintain the right to instruct independent counsel in regards to any Claim and to participate as a party in the defence thereof.</li>
                          <li>In the event that the Company or any relevant related body fails to notify the User of their intention to undertake the defence of the Claim, the User shall have the right to defend the Claim with counsel reasonably acceptable to the defending party. This is subject to the defending party’s right to assume the defence of the claim at any time prior to settlement or final determination.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Notification of Copyright Infringement</span>
                        <ol>
                          <li>The Company respects all intellectual property rights of others, and this is a requirement of Users and visitors of the Site and Services. In the event that the User has reason to believe that any User Content on the Site infringes upon a copyright or other such intellectual property right they should notify the company by providing the following:
                            <ol>
                                <li>The User’s name and the name of the company or organisation, if applicable;</li>
                                <li>The User’s contact information. This should include at least a full e-mail address, physical address and telephone number;</li>
                                <li>A method of identifying the User Content that may be an infringement with enough detail so that the Company may easily locate it. This should be done through provision of the URL of the page on the Site where the infringing content is found, as well as directions to its location on the page where appropriate. </li>
                                <li>A statement from the complaining party setting out that it has a good faith belief that material in question is not authorized by the copyright owner or other controlling body; </li>
                                <li>A signature, be it physical or electronic, of an individual authorized to act on behalf of the owner of the copyright that has been allegedly infringed; and </li>
                                <li>A signed statement that the information in the notification is accurate to the best of their knowledge and belief and, under penalty of perjury, that the party submitting the complaint has authority to act on behalf of the ultimate copyright owner of that has been allegedly infringed.</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li class="main"><span>Termination of the Agreement</span>
                        <ol>
                          <li>The Company reserves the right to, at any time immediately and without notice, terminate the Agreement and thereby terminate the User’s access to the Site and the Services if:
                            <ol>
                                <li>for any reason whatsoever the Company decides to discontinue its Services or the Site or any part thereof, be that in for all users and visitors or specifically to the User;</li>
                                <li>the Company has reason to believe that the User has breached any of the terms of the Agreement;</li>
                                <li>in their usage of the site the User has acted in any way contrary to the letter or purpose of the Agreement; or</li>
                                <li>on any other reasonable grounds that the Company sees fit.</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li class="main"><span>Governing Law</span>
                        <ol>
                          <li>These Terms, the rights and remedies provided hereunder, and any and all claims and disputes related hereto and/or to the Services, shall be governed by, construed under and enforced in all respects solely and exclusively in accordance with the laws of Jersey. Any and all such claims and disputes shall be brought in, and the User hereby consents to them being decided exclusively by a court of competent jurisdiction located in Jersey.</li>
                        </ol>
                      </li>
                      <li class="main"><span>Confidentiality</span>
                        <ol>
                          <li>In visiting or using this Site or by using the Services, the User agrees that, unless as directed by the Company, the User shall keep all information relating to any dispute relating to this Agreement strictly confidential during the term of the Agreement or any time thereafter. The User will not exploit in any way access to Confidential information for any purpose, save for that which is strictly relevant to resolve the Dispute in absolute confidence, or as a part of the Arbitration process</li>
                          <li>Without generality to the foregoing, the User will be permitted disclosure of such Confidential Information if required by law provided that the User promptly notify, consult with and cooperate with the Company insofar as to allow the control of the release of disclosure or to obtain an assurance or order that such Confidential Information will be accorded confidential treatment.</li>
                        </ol>
                      </li>
                    </ol>
                </div>
            </Container>
        </div>
      </section>
    </>
  )
}
export default TermsConditions