import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';
import { getCityPayPaymentResponse, getUser } from '../../apiservices/ApiServices';

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Loader from '../../components/Loader/Loader';

import { format } from 'date-fns';

const SuccessPayment = () => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('userToken') && !localStorage.getItem('userIdentifier')) {
            navigate("/");
        }
        else {
            let Identifier = localStorage.getItem('userIdentifier')
            getCityPayPaymentResponse(Identifier).then((res) => {
                try {
                    setData(res.data)
                    console.log(res.data)
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

            getUser().then((res) => {
                try {
                    setUser(res.data);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });
        }
    }, [])

    const convertDateTimeFormat = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return format(date, 'dd-MM-yyyy hh:mm a');
      };

    return (
        <>
            <Header UserData={user} />
            <section id='pagecontent'>
                <div className='pagecontent-box'>
                    <Sibebar UserData={user} />
                    <div className='contents'>
                        {!isLoading ?
                            <>
                                {data.status == 'Approved' ?
                                    <div className='paycdr'>
                                        <div className='payicon'>
                                            <FaCheckCircle />
                                        </div>
                                        <p>Payment Success!</p>
                                        <h4>{'£' + data.amount + '.00'}</h4>
                                        <div className='payinfo'>
                                            <ul>
                                                <li><h4>Transaction Number :</h4></li>
                                                <li><p>{data.transactionNumber}</p></li>
                                            </ul>
                                            <ul>
                                                <li><h4>Created on :</h4></li>
                                                <li><p>{convertDateTimeFormat(data.createdOn)}</p></li>
                                            </ul>
                                            <ul>
                                                <li><h4>Updated on :</h4></li>
                                                <li><p>{convertDateTimeFormat(data.updatedOn)}</p></li>
                                            </ul>
                                            <ul>
                                                <li><h4>Payment Source :</h4></li>
                                                <li><p>{data.paymentSource}</p></li>
                                            </ul>
                                            <ul>
                                                <li><h4>Remark :</h4></li>
                                                <li><p>{data.remark}</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    :
                                    <div className='paycdr failure'>
                                        <div className='payicon'>
                                            <FaTimesCircle />
                                        </div>
                                        <p>Payment Failed!</p>
                                    </div>
                                }
                            </>
                            : <Loader />
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default SuccessPayment