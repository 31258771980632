import React from 'react'

const Cards = (props) => {
  return (
    <div className='cardsbox'>
        <div className='count-left'>
            <p>{props.Title}</p>
            <h4>{props.Count}</h4>
        </div>
        <div className='count-right'>
            <span>{props.Icon}</span>
        </div>
    </div>
  )
}

export default Cards