import * as Yup from 'yup';
const phoneRegExp = /^447\d{9}$/;

export const loginSchema = Yup.object({
    email: Yup.string().email().required('Please Enter your Email Address'),    
    password: Yup.string().min(5).required('Please Enter Password'),     
})

export const registerSchema = Yup.object({ 
    email: Yup.string().email().required('Please Enter your Email Address'),
    mobile: Yup.string().matches(phoneRegExp, 'Mobile No. is not valid').required('Please Enter your Mobile No.'),
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('New Password is required'),
    confirmpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),  
})

export const forgotPasswordSchema = Yup.object({
    email: Yup.string().email().required('Please Enter your Email Address'),
})

export const changResPaaSchema = Yup.object({    
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('New Password is required'),

    confirmpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),       
})

export const addcrdSchema = Yup.object({
    credits: Yup.number().required('Please Enter Amount'),       
})

export const shortcodeentrySchema = Yup.object({
    shortcode: Yup.string().required('Please Select Shortcode / Campaign'),
    answer: Yup.string().min(1).required('Please Enter answer'),     
})