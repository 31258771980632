import React from 'react';
import { Container } from 'react-bootstrap';
import Logo from "../../assets/images/orzilogo.svg";
import {Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <header id='header'>
          <div className='header-block'>
              <div className='logo-box' id='heds'>
                  <Link to={'/'}>
                      <img src={Logo} alt='' title='' />
                  </Link>
              </div>
              <div className='rightnav'>
                  <ul>
                      <li><h6>ORZI.App</h6></li>                        
                  </ul>
              </div>
          </div>
      </header>
      <section id="contents">
        <div class="contents-block">
            <Container>
                <div class="contents-box">
                    <h1>Privacy Policy</h1>
                    <p>Ezo Connect ltd Trading as ‘ORZI’ referred to as 'Ezo Connect', 'we' or 'our' is committed to protecting the privacy and security of your personal information.</p>
                    <p>This Privacy Notice describes how we collect and use personal information about you during and after your relationship with us under the Data Protection (Jersey) Law 2018.</p>
                    <p>Ezo Connect Ltd is a "Data Controller", which means we are responsible for deciding how we hold and use personal information.</p>
                    <p>We are required under data protection legislation to notify you of the information contained in this Privacy Notice.</p>
                    <p>Ezo Connect is registered with the Jersey Office of the InformationCommissioner - <br/> Registration No. 65355</p>
                    <p>We will comply with data protection law, the principles of which says that the personal information we hold about you must be:-</p>
                    <ol>
                      <li>Used lawfully, fairly and in a transparent way.</li>
                      <li>Collected only for valid purposes that we have clearly explained to you and not used in any way incompatible with those purposes.</li>
                      <li>Relevant to the purposes we have told you about and limited only to those purposes.</li>
                      <li>Accurate and kept up to date.</li>
                      <li>Kept your data only as long as necessary for the purposes we collected it.</li>
                      <li>Kept securely.</li>
                    </ol>
                    <p>We will also only transfer your information when it is safe to do so, when necessary, and your information is protected in the same way that we protect it.</p>
                    <p>Ezo Connect is accountable to you and the Authorities to show compliance with the data protection laws and demonstrate how we are compliant.</p>

                    <h4>The Data Protection Manager (DPM)</h4>
                    <p>We have appointed a DPM to oversee compliance with this Privacy Notice.</p>
                    <p>If you have any questions about this Privacy Notice or how we handle your personal information, contact the DPM at <a href="mailto:DPM-Ezo@propelfwd.com">DPM-Ezo@propelfwd.com</a></p>
                    <p>You have the right to make a complaint at any time to the Jersey Office of the information Commissioner.</p>
                    <p>Contact details for these are outlined at the bottom of this Privacy Notice.</p>

                    <h4>The Kind of Information We Hold About You</h4>
                    <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
                    <p>To explain this to you, we will list our different services when we collect or use additional information about you.</p>

                    <h4>How Is Your Personal Information Collected?</h4>
                    <p>We collect personal information about you when you contact us, enquire about our services, fill in a form on our website, opt-in for marketing communications, work with us to integrate our services into your business operations, or provide us with your data for another specific purpose.</p>
                    <p>What information do we collect about you?</p>
                    <ul class="grid">
                      <li>Name</li>
                      <li>Telephone number</li>
                      <li>Title</li>
                      <li>Occupation</li>
                      <li>Address</li>
                      <li>Email address</li>
                      <li>Your enquiries</li>
                      <li>Your responses to survey’s</li>
                    </ul>

                    <h4>What Special Category Data do we collect about you?</h4>
                    <p>When we collect Special Category data, we will always have a clear legal basis to process this or your explicit consent. If we rely on your explicit consent, you can withdraw this at any time by contacting our DPM at <a href="mailto:DPM-Ezo@propelfwd.com">DPM-Ezo@propelfwd.com</a></p>
                    <p>Special Category data is any information relating to your health, gender, race, sexual orientation, sex life, genetic or biometric data etc.</p>

                    <h4>How We Will Use Information About You?</h4>
                    <p>We will only use your personal information when the law allows us to.</p>
                    <p>Most commonly, we will use your personal information in the following circumstances:</p>
                    <ol>
                      <li>You have entered into a <b>contract</b> with us to provide you with a service; an example of this would be when you register to use one of our services.</li>
                      <li>We need to comply with a <b>legal obligation;</b> an example of this would be when we have to comply with lawful authority to disclose information to the Police.</li>
                      <li>Where it is necessary for our <b>legitimate interests</b> (or those of a third party), your interests and fundamental rights do not override those interests. An example of this is when we have had a previous relationship with you and we believe you would be interested to hear about a specific promotion we are running. <br />
                        We may also use your personal information in the following situations, which are likely to be rare:
                        <ol>
                          <li>Where you have given you <b>consent</b> to contact you for a specific purpose. An example of this would be when you opt-in to receive marketing communications from us.</li>
                          <li>In an emergency, it is in your <b>vital interest</b> to share your information when we have it recorded.</li>
                        </ol>
                      </li>
                    </ol>
                    <p>In instances where we have been contracted by a client to create video advertisements that may contain faces of employees, we rely on <b>Legitimate Interest</b> as our legal basis for processing. The creation and processing of video advertisements for our clients is necessary for our legitimate interest (or those of a third party) and your interests and fundamental rights do not override those interests.</p>

                    <h4>Change of purpose</h4>
                    <p>We will only use your personal information for the purposes we collected it unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.</p>
                    <p>If we need to use your personal information for an unrelated purpose, we will notify you and explain the legal basis that allows us to do so.</p>

                    <h4>Data Sharing</h4>
                    <p>We may, on occasions, pass your Personal Information to an organisation outside of Ezo Connect exclusively to process on our behalf; these organisations are called Data Processors.</p>
                    <p>We require these parties to agree to process this information based on our instructions and requirements consistent with this Privacy Notice.</p>
                    <p>We do not pass on information gained from your engagement with us without a clear legal basis for doing so. However, we may disclose your Personal Information to meet legal obligations, regulations, or valid governmental requests such as a Police request.</p>
                    <p>The personal data we collect from you may be transferred to and stored at a destination outside the European Economic Area ("EEA").</p>
                    <p>It may also be processed by companies outside the EEA who work for one of our service providers or us.</p>
                    <p>If we do this, we ensure that your privacy rights are respected in line with this Policy and the same protection is given to your personal information as laid down by the DPJL.</p>

                    <h4>Data Security</h4>
                    <p>We have put in place appropriate security measures to prevent your personal information from being accidentally lost, altered or disclosed, or accessed in an unauthorised way. In addition, we limit access to your personal information to those Colleagues, Agents, Contractors and other third parties on a need to know basis.</p>
                    <p>They will only process your personal information on our strict instructions, and they are subject to a duty of confidentiality.</p>
                    <p>Details of these measures may be obtained from our Data Protection Manager (DPM).</p>
                    <p>We have put in place procedures to deal with any suspected data security breach and notify you and any applicable regulator of a suspected breach where we are legally required to do so.</p>

                    <h4>Data Retention</h4>
                    <p>We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including to satisfy any legal, accounting, or reporting requirements.</p>
                    <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your data. The purposes for which we process your data and whether we can achieve those purposes through other means and the applicable legal requirements.</p>
                    <p>You can request our Data Retention Schedule from our DPM if required.</p>
                    <p>In some circumstances, we may anonymise your personal information so that it can no longer be associated with you, in which case we may use such information without further notice to you.</p>


                    <h4>Rights of Access, Correction, Erasure, and Restriction</h4>
                    <p>The personal information we hold about you must be accurate and current.</p>
                    <p>Please keep us informed if your information changes during your relationship with us.</p>
                    <p>Under certain circumstances, by law, you have the right to:</p>
                    <div class="subcontents">
                        <p><b>Request access</b> to your personal information (commonly known as a "data subject access request"). This right enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.</p>
                        <p><b>Request correction</b> of the personal information that we hold about you. This right enables you to have any incomplete or inaccurate data we hold about you corrected.</p>
                        <p><b>Request erasure</b> of your personal information. This right enables you to ask us to delete or remove personal data where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</p>
                        <p><b>Object to processing</b> your personal information where we rely on a legitimate interest (or those of a third party). There is something about your particular situation that makes you want to object to processing on this ground. You also have the right to object to processing your personal information for direct marketing purposes.</p>
                        <p><b>Request the restriction of processing</b> of your personal information. This right enables you to ask us to suspend the processing of personal information about you, for example, if you want us to establish its accuracy or the reason for processing it.</p>
                        <p><b>Request the transfer</b> of your personal information to another party.</p>
                    </div>
                    <p>If you want to review, verify, correct or request the erasure of your personal information, object to the processing or request that we transfer a copy of your personal information to another party, contact our DPM at <a href="mailto:DPM-Ezo@propelfwd.com.">DPM-Ezo@propelfwd.com.</a></p>
                    <p>You will not have to pay a fee to access your personal information (or exercise any other rights). However, we may charge a reasonable fee if your access request is unfounded or excessive. Alternatively, we may refuse to comply with the request in such circumstances.</p>

                    <h4>How to request your information and what we may need from you</h4>
                    <p>Use the Data Subject Access Request form, available on our Cookie Banner. If you have any questions about this process or any of your rights, contact our DPM at <a href="Ezo@propelfwd.com">DPM - Ezo@propelfwd.com.</a></p>
                    <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or exercise any of your other rights).</p>
                    <p>We will respond to your request within <b>4 weeks</b> after and upon satisfactory verification of your identity.</p>

                    <h4>Right to Withdraw Consent</h4>
                    <p>n the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal information for a specific purpose, you have the right to withdraw your consent for that specific processing at any time.</p>
                    <p>To withdraw your consent, contact our DPM at <a href="mailto:DPM-Ezo@propelfwd.com.">DPM-Ezo@propelfwd.com.</a></p>
                    <p>Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to unless we have another legitimate basis for doing so in law.</p>

                    <h4>Changes to this Privacy Notice</h4>
                    <p>We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates.</p>
                    <p>We may also notify you in other ways from time to time about the processing of your personal information or requesting you to confirm the accuracy of the information we hold on you.</p>

                    <h4>Complaints</h4>
                    <p>We want the chance to resolve any complaints you have about how we process your information. You have the right to complain to the Jersey Office of the Information Commissioner about how we have used your data.</p>
                    <br />
                    <p>The details for each of these contacts are:</p>
                    <p><b>Ezo Connect ltd – Data Protection Manager</b></p>
                    <p>Propelfwd, Second Floor, 6 Vine Street, St Helier, Jersey, JE2 4WB Telephone <a href="tel:+4401534735330">+44 (0) 1534 735330</a> or email <a href="mailto:DPM-Ezo@propelfwd.com">DPM-Ezo@propelfwd.com</a></p>
                    <br />
                    <p><b>Jersey Office of the Information Commissioner (JOIC)</b></p>
                    <p>2<sup>nd</sup> Floor, 5 Castle Street, St Helier, Jersey, JE2 3BT <br /> Telephone <a href="tel:+4401534735330">+44 (0) 1534 735330</a> or email <a href="mailto:enquiries@jerseyoic.org">enquiries@jerseyoic.org</a></p>
                </div>
            </Container>
        </div>
      </section>
    </>
  )
}
export default PrivacyPolicy