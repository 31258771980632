import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';

import { FaKey, FaTrashAlt  } from "react-icons/fa";
import Loader from '../../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, postchangePassword, postUpdateuser, getDeletePublicUser } from '../../apiservices/ApiServices';

import Alert from 'react-bootstrap/Alert';

const MyProfile = () => {
    const [isLoading, setLoading] = useState(false);
    const [isLoadings, setLoadings] = useState(true);
    const [data, setData] = useState([]);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [factor, setFactor] = useState(0);
    const [isFa, setIsfa] = useState('no');
    const [isEn, setIsen] = useState('email');

    const [shows, setShows] = useState(false);

    const navigate = useNavigate();

    useEffect(() => 
    {
        if(!localStorage.getItem('userToken')) 
        {
            navigate("/");
        }
        else 
        {
            getUser().then((res) => 
            {
                try {
                    console.log(res.data)
                    setData(res.data);
                    setLoadings(false);

                    if (res.data) {
                        if (res.data.is2FactorAuthentication == true) {
                            setIsfa('yes');
                            setIs2FAEnabled(true);
                        }
                        else {
                            setIsfa('no');
                            setIs2FAEnabled(false);
                        }
    
                        if (res.data.authenticationId == 2) {
                            setIsen('mobile');
                        }
                        else {
                            setIsen('email');
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    
                }
            });
        }
    }, [])

    const handleRadioChange = (event) => {
        let value=event.target.value;
        if (value == 'yes') {
            setIs2FAEnabled(true);
            if (data.authenticationId == 2) {
                setIsen('mobile');
                setFactor(2);
            }
            else {
                setIsen('email');
                setFactor(1);
            }
        }
        else {
            setIs2FAEnabled(false);
            setFactor(0);
        }

        setIsfa(value);

    };

    const handleRadioChangefactor = (event) => {
        let rdoval=event.target.value;
        if(rdoval == 'email')
        {
            setFactor(1);
        }
        else if(rdoval == 'mobile')
        {
            setFactor(2);
        }
        else {
            setFactor(0);
        }
        setIsen(rdoval)
    };  

    function handlechangpass() {
        setLoading(true)
        let EmailId = data.emailId;
        postchangePassword(EmailId).then((res) => {
            try {
                console.log('res', res)
                setLoading(false);
                toast.success('A link has been sent to your registered email Id to change password');   
            } catch (error) {
                setLoading(false);
                console.error(error);
            } finally {
                setLoading(false);
            }
        });
    }

    function handleSubmit() {
        setLoading(true)
        let userdata =
        {           
            "emailId": data.emailId,
            "mobilenumber": data.mobilenumber,
            "is2FactorAuthentication": is2FAEnabled,
            "authenticationId": factor,              
        }

        postUpdateuser(userdata).then((res) => {
            try {
                console.log('res', res)
                toast.success(res.data.message);
                setLoading(false); 
            } catch (error) {
                setLoading(false);
                console.error(error);
            } finally {
                setLoading(false);
            }
        });
    }

    function handleDelete() {
        setLoading(true)
        let uid = data.publicUserId;
        setLoading(true);
        getDeletePublicUser().then((res) => {
            try {
                console.log('res', res)
                if(res.data.succeeded == true){
                    toast.success(res.data.message); 
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userIdentifier');
                    setTimeout(() => navigate("/"), 500); 
                }
                else{
                    toast.success(res.data.message); 
                }
                       
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            } finally {
                setLoading(false);
            }
        });
    }
    
    return (
        <>
            <Header UserData={data} />
            <section id='pagecontent'>
                <ToastContainer />
                <div className='pagecontent-box'>
                    <Sibebar UserData={data} />
                    <div className='contents'>
                        <div className='pagetitle'>
                            <h1>My Profile</h1>
                        </div>
                        <div className='cards tables mt-2'>
                            <div className='cards-hedar'>
                                <h4>My Profile</h4>
                                <div className='btnsbox'>
                                    <button type='button' onClick={handlechangpass} className='btn btn-yellow'><FaKey /> Change Password</button>
                                </div>
                            </div>
                            {isLoadings ? ( 
                                <Loader />
                            ) :
                            <div className='cards-body'>
                                <div className='cardinfo forms'>
                                    <div className='usersinfos'>
                                        <p><b>User Name :</b> {data.emailId}</p>
                                        <p><b>User Mobile :</b> {data.mobilenumber} </p>
                                        <p><b>ORZI Credit :</b> {data.availableCredit}</p>
                                    </div>

                                    <div className='form-group'>
                                        <div className='rdioboxs d-flex'>
                                            <label>Is Two Factor Authentication On?</label>
                                            <div className='inputrdio'>
                                                <input 
                                                    type="radio"
                                                    id="2fa-yes"
                                                    name="2fa"
                                                    value="yes"
                                                    checked={isFa == 'yes'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="2fa-yes">Yes</label>
                                            </div>
                                            <div className='inputrdio'>
                                                <input
                                                    type="radio"
                                                    id="2fa-no"
                                                    name="2fa"
                                                    value="no"
                                                    checked={isFa == 'no'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="2fa-no">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    {is2FAEnabled && (
                                        <div className='form-group'>
                                        <div className='rdioboxs'>
                                            <label>Select Two Factor Authentication Type</label>
                                            <div className='inputrdio'>
                                                <input 
                                                    type="radio"
                                                    id="email"
                                                    name="factor"
                                                    value="email"
                                                    checked={isEn == 'email'}
                                                    onChange={handleRadioChangefactor}
                                                />
                                                <label htmlFor="email">Email</label>
                                            </div>
                                            <div className='inputrdio'>
                                                <input
                                                    type="radio"
                                                    id="mobile"
                                                    name="factor"
                                                    value="mobile"
                                                    checked={isEn == 'mobile'}
                                                    onChange={handleRadioChangefactor}
                                                />
                                                <label htmlFor="mobile">Mobile</label>
                                            </div>
                                        </div>
                                    </div>
                                    )}

                                    <div className='buttonboxs group'>
                                        <button type='button' onClick={handleSubmit} className='btn btn-green'>Submit</button>
                                        <button type='button' onClick={() => setShows(true)} className='btn btn-red'><FaTrashAlt /> Delete Account</button>
                                    </div>

                                    <div style={{marginTop: 30}}>
                                        <p>Please Check our <Link className='Links' to={'/TermsConditions'}>Terms and Conditions</Link> & <Link className='Links' to={'/PrivacyPolicy'}>Privacy Policy</Link></p>
                                        <p>Contact us  <a className='Links' href='mailto:support@orzi.co.uk'>support@orzi.co.uk</a></p>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : null}
            </section>

            {shows ? (<>
                <Alert variant="danger" onClose={() => setShows(false)} dismissible>
                    <Alert.Heading>Are you sure want to Delete Account?</Alert.Heading>
                    <hr />
                    <div className='buttons'>
                        <div>
                            <button onClick={() => handleDelete()} className='btn btn-green'>OK</button>
                        </div>
                        <div>
                            <button onClick={() => setShows(false)} className='btn btn-red'>Cancel</button>
                        </div>
                    </div>
                </Alert>
                <div className='overly'></div>
                </>) : null}
        </>
      )
}

export default MyProfile