import React from 'react';
import { Routes, Route } from "react-router-dom";

import Login from '../auth/Login';
import Register from '../auth/Register';
import ForgotPassword from '../auth/ForgotPassword';
import ChangePassword from '../auth/ChangePassword';
import EmailVerification from '../auth/EmailVerification';

import Dashboard from './Dashboard/Dashboard';
import ShortcodeEntry from './ShortcodeEntry/ShortcodeEntry';
import AddCredit from './AddCredit/AddCredit';
import ParticipantHistory from './ParticipantHistory/ParticipantHistory';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import MyProfile from './MyProfile/MyProfile';
import ContactUs from './ContactUs/ContactUs';
import TermsConditions from './TermsConditions/TermsConditions';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import SuccessPayment from './SuccessPayment/SuccessPayment';
import Error from './Error/Error';

import '../assets/fonts/font.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/style/style.css";
import "../assets/style/responsive.css";

const Main = () => {
    return (
        <>
          <React.Fragment>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/user/change-password/:id/:token" element={<ChangePassword />} />
                <Route exact path="/user/EmailConfirmed" element={<EmailVerification />} />
                
                <Route exact path="/activity/dashboard" element={<Dashboard />} />
                <Route exact path="/activity/add-participant" element={<ShortcodeEntry />} />
                <Route exact path="/activity/add-participant/:id" element={<ShortcodeEntry />} />
                <Route exact path="/activity/add-credit" element={<AddCredit />} />
                <Route exact path="/activity/participant-history" element={<ParticipantHistory />} />
                <Route exact path="/activity/payment-history" element={<PaymentHistory />} />
                <Route exact path="/user/my-profile" element={<MyProfile />} />
                <Route exact path="/user/contact-us" element={<ContactUs />} />

                <Route exact path="/TermsConditions" element={<TermsConditions />} />
                <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />


                <Route exact path="/user/Success-Payment" element={<SuccessPayment />} />

                <Route exact path="*" element={<Error />} />

            </Routes>
          </React.Fragment>
        </>
      );
}

export default Main