import React, { useEffect, useState } from 'react';
import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';
import { getUser } from '../../apiservices/ApiServices';
import { useNavigate } from 'react-router-dom';
const ContactUs = () => {
    const [user, setUser] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
     
    useEffect(() => {
        setLoading(true);
        if (!localStorage.getItem('userToken')) {
          navigate("/");
        }
        else {
          getUser().then((res) => 
          {
              try {
                  setUser(res.data);
                  setLoading(false);
              } catch (error) {
                  console.error(error);
              } finally {
                  setLoading(false);
              }
          });
        }
      }, [navigate])
    return (
        <>
            <Header UserData={user}/>
            <section id='pagecontent'>
                <div className='pagecontent-box'>
                    <Sibebar UserData={user}/>
                    <div className='contents'>
                        <div className='pagetitle'>
                            <h1>Contact Us</h1>
                        </div>
                        <div className='cards tables mt-2'>
                            <div className='cards-hedar contactus'>
                                <p>Contact us : <a className='links' to={'mailto:support@orzi.co.uk'} target='_blank'>support@orzi.co.uk</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
      )
}

export default ContactUs