import React from 'react'
import Loderimg from '../../assets/images/loder.gif';

const Loader = () => {
  return (
    <div className='pageloder'>
        <img src={Loderimg} alt='Loder' title='Loder' />
    </div>
  )
}

export default Loader