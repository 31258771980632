import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaTachometerAlt, FaEnvelope, FaCreditCard, FaChartPie, FaUser, FaPowerOff } from "react-icons/fa";
import { $ } from 'react-jquery-plugin';
import Logo from "../assets/images/orzilogo.svg";
import Alert from 'react-bootstrap/Alert';

const Sibebar = (props) => {
    const navigate = useNavigate();
    const [isNav, setNav] = useState(false);
    const [show, setShow] = useState(false);

    function logOut() {
        setShow(false);
        localStorage.removeItem('userToken');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userIdentifier');
        navigate("/");
        window.location.reload(true);
    }

    useEffect(() => {
        if ($(window).width() <= 1024) {
            $(document).on("click", "#nav ul li a", function (e) {
                $("#nav ul").removeClass('open');
                setNav(false)
            });
        }
    }, [])
    return (
        <>

            <button onClick={() => setNav(!isNav)} className="menu-icon"><span></span><span></span><span></span></button>
            <div className={isNav ? 'leftnav open' : 'leftnav'} >
                <div className="res userinfoboxs">
                    <div className='logoresbox'>
                        <img src={Logo} alt='logo' title='logo' />
                    </div>
                    <div className='userinfo'>
                        <p>Welcome</p>
                        <h6>{props.UserData.emailId}</h6>
                    </div>
                    <div className="btns">
                        <Link className='btn btn-white' to={'/activity/add-credit'}>Available Credits : {props.UserData.availableCredit}</Link>
                    </div>
                    <div className="btns rrr">
                        <Link className='btn btn-white' to={'/user/contact-us'}><FaEnvelope /> Contact</Link>
                    </div>

                </div>
                <nav id='nav'>
                    <ul>
                        <li><NavLink to={'/activity/dashboard'}><FaTachometerAlt /><span>Dashboard</span></NavLink></li>
                        <li><NavLink to={'/activity/add-participant'}><FaEnvelope /><span>Shortcode Entry</span></NavLink></li>
                        <li><NavLink to={'/activity/add-credit'}><FaCreditCard /><span>Add Credit</span></NavLink></li>
                        <li><NavLink to={'/activity/participant-history'}><FaChartPie /><span>Participant History</span></NavLink></li>
                        <li><NavLink to={'/activity/payment-history'}><FaChartPie /><span>Payment History</span></NavLink></li>
                        <li><NavLink to={'/user/my-profile'}><FaUser /><span>My Profile</span></NavLink></li>
                        <li><Link onClick={() => setShow(true)}><FaPowerOff /><span>Log Out</span></Link></li>
                    </ul>
                </nav>
            </div>
            <div id="overly" className={isNav ? 'open' : ''} onClick={() => setNav(!isNav)}></div>

            {show ? (<>
                <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>Are you sure want to Logout?</Alert.Heading>
                    <hr />
                    <div className='buttons'>
                        <div>
                            <button onClick={() => logOut()} className='btn btn-green'>OK</button>
                        </div>
                        <div>
                            <button onClick={() => setShow(false)} className='btn btn-red'>Cancel</button>
                        </div>
                    </div>
                </Alert>
                <div className='overly'></div>
                </>) : null}
        </>
    )
}

export default Sibebar